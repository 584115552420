import React, {useState, useRef, useEffect} from 'react';
import './App.css';
import Sidebar from './components/Sidebar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import ExternalLink from './components/ExternalLink';

function App() {
  const [homeVisible, setHomeVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);
  const [pageOnView, setPageOnView] = useState('');

  //elements references
  const homeRef = useRef();
  const aboutRef = useRef();
  const contactRef = useRef();

  //observers options
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  }

  //one callback for each observed element
  //home
  const homeCallback = (entries) => {
    const [entry] = entries;
    setHomeVisible(entry.isIntersecting)
    entry.isIntersecting && setPageOnView('home')
  }

  //about
  const aboutCallback = (entries) => {
    const [entry] = entries;
    setAboutVisible(entry.isIntersecting)
    entry.isIntersecting && setPageOnView('about')
  }

  //contact
  const contactCallback = (entries) => {
    const [entry] = entries;
    setContactVisible(entry.isIntersecting)
    entry.isIntersecting && setPageOnView('contact')
  }

  useEffect(() => {
    //create one observer for each element with its callback
    const homeObserver = new IntersectionObserver(homeCallback, options);
    const aboutObserver = new IntersectionObserver(aboutCallback, options);
    const contactObserver = new IntersectionObserver(contactCallback, options);
    if(homeRef.current && aboutRef.current){
      //starts to observe
      aboutObserver.observe(aboutRef.current);
      homeObserver.observe(homeRef.current);
      contactObserver.observe(contactRef.current);
    }
    return () => {
      if(homeRef.current && aboutRef.current){
        //clean up
        aboutObserver.unobserve(aboutRef.current);  
        homeObserver.unobserve(homeRef.current);
        contactObserver.unobserve(contactRef.current);
      }
    }
  }, [homeRef, aboutRef])
  return (
    <>
      <ExternalLink />
      <Sidebar pageOnView={pageOnView}/>
      <Home homeRef={homeRef}/>
      <About aboutRef={aboutRef}/>
      <Contact contactRef={contactRef}/>
    </>
  );
}

export default App;
