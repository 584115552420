import React from 'react';

const Container = (props) => {
    return (
        <div {...props} className={`container mx-auto px-4 lg:px-12 ${props.className}`} >
            {props.children}
        </div>
    )
}

export default Container
