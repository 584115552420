import React, {useState, useEffect} from 'react';
import { motion, AnimatePresence } from 'framer-motion'; 
import { config } from '../emailjs/config';
import emailjs from 'emailjs-com';

const ListItem = ({item}) => (
    <motion.li className='flex gap-4 text-gray-700 py-2 items-center self-start'
        whileHover={{ scale: 1.1 }}
    >
        <img 
            className='w-auto h-4'
            src={item.imgUrl} alt='contact_ico'
        />
        <a href={item.href} className='text-sm hover:text-purple-900'>{item.value}</a>
    </motion.li>
)

const Input = (props) => (
    <input className='px-2 py-4 w-full bg-purple-50 bg-opacity-80 placeholder-gray-400 focus:outline-purple' {...props}/>
)

const Message = (props) => (
    <textarea className='w-full h-48 px-2 py-4 bg-purple-50 bg-opacity-80 placeholder-gray-400 focus:outline-purple' {...props}/>
)

const Spinner = () => (
    <img
        className='w-6 h-6 mx-auto' 
        src={require('../assets/spinner.gif').default} 
        alt='sending email' />
)

const Button = ({loading}) => (
    <button 
        className='border-2 border-gray-700 py-4 text-xl text-gray-700 font-semibold hover:bg-purple-200 hover:bg-opacity-80 hover:border-purple-900'
        type='submit'
    >
        {!loading ? 'SEND' : <Spinner />}
    </button>
)

const Toast = ({hide}) => {
    setTimeout(() => {
        hide()
    }, 3000)

    return (
        <motion.div className="fixed top-20 right-4 bg-purple-400 rounded-xl text-gray-50 px-4 py-2 shadow-lg"
            initial={{ x: '100vh '}}
            animate={{ x: 0 }}
            exit={{ x: '100vh' }}
        >
            Your message has been sent
        </motion.div>
    )
}

const Contact = ({contactRef}) => {
    const [email, setEmail] = useState({
        to_name: 'Hakan',
        sender_name: '',
        sender_phone: '',
        sender_email: '',
        message: '',
        house_address: ''
    })
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const handleChange = (e) => {
        setEmail({
            ...email,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const { SERVICE_ID, TEMPLATE_ID, USER_ID } = config;
        setLoading(true);
        emailjs.send(SERVICE_ID, TEMPLATE_ID, email, USER_ID)
        .then(() => {
            setLoading(false);
            setShowMessage(true);
            e.target.reset();
        })
        .catch(error => {
            console.error(error);
        })
    }

    return (
        <>  
            <AnimatePresence exitBeforeEnter>
                {showMessage && <Toast hide={() => setShowMessage(false)}/>}
            </AnimatePresence>
            <section className='flex h-full bg-gray-300 bg-opacity-90 lg:flex-row-reverse' id='contact' ref={contactRef}>
                <div className='flex py-20 w-full min-h-screen lg:w-1/2'>
                    <div className=' w-full lg:max-w-xl m-auto px-4'>
                        <h1 className='text-3xl text-gray-50 text-center font-bold text-gray-700 font-anton'>Contact Me</h1>
                        <ul className='flex flex-col w-min mx-auto mt-8'>
                            <ListItem 
                                item={{ 
                                    href: 'Tel: 702-849-6065', 
                                    value: '+1 (702) 849-6065' ,
                                    imgUrl: require('../assets/phone_icon.png').default
                                }}
                            />
                            <ListItem 
                                item={{ 
                                    href: 'mailto:hakan@vegasbestrealtor.com', 
                                    value: 'hakan@vegasbestrealtor.com' ,
                                    imgUrl: require('../assets/email_icon.png').default
                                }}
                            />
                        </ul>
                        <p className='text-xs text-purple-700 mt-12'>*or send me a message below.</p>
                        <p className='text-xs text-purple-700 mt-2'>*if you want want to know how much your house is worth fill up the address field (NOT REQUIRED).</p>
                        <form 
                            className='py-2 flex flex-col gap-2'
                            onSubmit={handleSubmit}
                        >
                            <Input type="text" name="sender_name" placeholder='NAME' onChange={handleChange} required />
                            <Input type="email" name="sender_email" placeholder='EMAIL' onChange={handleChange} required />
                            <Input type="phone" name="sender_phone" placeholder='PHONE' onChange={handleChange} required />
                            <Input type="text" name="house_address" placeholder='STREET, CITY, STATE, ZIP' onChange={handleChange}/>
                            <Message placeholder='MESSAGE' name='message'  onChange={handleChange} required />
                            <Button loading={loading} />
                        </form>
                    </div>
                </div>
                <div className='hidden lg:block lg:flex lg:w-1/2 bg-gray-100 items-center justify-center'>
                    <img 
                        className='max-h-96'
                        src={require('../assets/contact_img.svg').default}
                    />
                </div>
            </section>
        </>
    )
}

export default Contact
