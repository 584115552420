import React from 'react'
import { motion } from 'framer-motion';

const ExternalLink = () => {
    return (
        <motion.div className='fixed bottom-16 right-4 p-2 bg-yellow-400 rounded-full z-50 shadow-md'
            initial={{ scale: 1.0 }}
            animate={{ scale: 1.1 }}
            transition={{ 
                repeat: Infinity,
                duration: 0.5,
                repeatType: 'reverse'
            }}
        >
            <a href='http://hakanaltunbas.signaturenevada.com' 
                className='text-sm text-purple-900 font-bold'
            >See houses here</a>
        </motion.div>
    )
}

export default ExternalLink
