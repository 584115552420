import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';

const MenuIcon = ({imgUrl, currentPage, showLink}) => (
    <div className='w-auto lg:w-8 h-4 lg:h-6 flex justify-center'>
        <img src={imgUrl} className={`mx-auto transform ${currentPage && 'scale-125'}`}/>
    </div>
)

const MenuLink = ({children, className, to, currentPage}) => (
    <a 
        className={`flex uppercase text-sm font-anton tracking-wider flex-col items-center gap-2 whitespace-nowrap lg:flex-row lg:gap-4 lg:hover:text-yellow-400 ${currentPage ? 'text-sm lg:text-lg text-gray-50' : 'text-purple-200' } ${className}`} 
        href={`#${to}`}
    >
        {children}
    </a>
)

const ListItem = ({imgUrl, to, pageName, pageOnView, showLink}) => {
    const [currentPage, setCurrentPage] = useState(false);

    useEffect(() => {
        setCurrentPage(pageOnView === pageName.toLowerCase())
    }, [pageOnView])


    return (
        <li className={`group p-2 w-max lg:w-full ${currentPage && 'border-b-4 border-yellow-400'}`}>
            <MenuLink to={to} currentPage={currentPage} >
                <MenuIcon imgUrl={imgUrl} currentPage={currentPage} showLink={showLink}/>
                <AnimatePresence exitBeforeEnter>
                    {showLink && (
                        <motion.span
                            initial={{ width: 0 }}
                            animate={{ width: 'auto' }}
                            exit={{ width: 0 }}
                            transition={{ duration: 0.2 }}
                        >
                            {pageName}
                        </motion.span>
                    )}
                </AnimatePresence>                
            </MenuLink>
        </li>  
    )
}

// const ListItem = ({imgUrl, to, pageName, pageOnView, showLink}) => {
//     const [currentPage, setCurrentPage] = useState(false);

//     useEffect(() => {
//         setCurrentPage(pageOnView === pageName.toLowerCase())
//     }, [pageOnView])


//     return (
//         <li className={`group p-2 w-max lg:w-full ${currentPage && 'border-b-4 border-yellow-400'}`}>
//             <MenuLink to={to} currentPage={currentPage} >
//                 <MenuIcon imgUrl={imgUrl} currentPage={currentPage} showLink={showLink}/>
//                 <AnimatePresence exitBeforeEnter>
//                     {showLink && (
//                         <motion.span
//                             initial={{ width: 0 }}
//                             animate={{ width: 'auto' }}
//                             exit={{ width: 0 }}
//                             transition={{ duration: 0.2 }}
//                         >
//                             {pageName}
//                         </motion.span>
//                     )}
//                 </AnimatePresence>                
//             </MenuLink>
//         </li>  
//     )
// }

const Sidebar = ({pageOnView}) => {
    const [hovering, setHovering] = useState(false); 

    return (
        <AnimateSharedLayout type="crossfade">
            <motion.aside 
                className='fixed left-0 top-0 w-full py-2 px-8 bg-gray-600 opacity-90 z-50 lg:h-full lg:w-max lg:p-4'
                onHoverStart={(e) => setHovering(true)}
                onHoverEnd={(e) => setHovering(false)}                
                transition={{ duration: 0.3 }}
                layout
            >
                <nav className='w-full h-full flex'>
                    <ul className='flex w-full gap-16 m-auto overflow-x-scroll justify-center items-center lg:overflow-hidden lg:flex-col lg:gap-4 lg:justify-start'>
                        <ListItem 
                            imgUrl={require('../assets/home_icon.png').default}
                            to='home'
                            pageName='Home'
                            pageOnView={pageOnView}
                            showLink={hovering}
                        />
                        <ListItem 
                            imgUrl={require('../assets/about_icon.png').default}
                            to='about'
                            pageName='About'
                            pageOnView={pageOnView}
                            showLink={hovering}
                        />
                        <ListItem 
                            imgUrl={require('../assets/contact_icon.png').default}
                            to='contact'
                            pageName='Contact'
                            pageOnView={pageOnView}
                            showLink={hovering}
                        />
                    </ul>
                </nav>
            </motion.aside>
        </AnimateSharedLayout>
    )
}

export default Sidebar
