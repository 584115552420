import React from 'react';
import { motion } from 'framer-motion';
import Container from './Container';

const Card = ({imgUrl, title, text}) => (
    <div className="flex flex-row bg-purple-200 justify-center mx-auto pr-2 rounded-2xl shadow-lg lg:flex-col lg:pr-0 lg:pb-4 lg:w-64 lg:rounded-3xl">
        <img src={imgUrl} className="bg-gray-300 w-1/3 lg:w-full h-auto rounded-tl-2xl rounded-bl-2xl lg:rounded-t-3xl lg:rounded-bl-none" />
        <div className="px-2 py-2 lg:px-4 lg:py-8 bg-gray-50 rounded-tr-2xl rounded-br-2xl lg:rounded-tr-none lg:rounded-b-3xl">
            <p className='text-left lg:text-center text-sm lg:text-lg font-semibold'>{title}</p>
            <p className='text-sm text-left lg:text-center'>{text}</p>
        </div>
    </div>
)

const About = ({aboutRef}) => {
    return (
        <section className='pt-20 pb-4 bg-gray-100' id='about' ref={aboutRef}>
            <Container>
                <div className="grid grid-cols-1 lg:grid-cols-about lg:grid-rows-about lg:gap-x-12 lg:items-start w-full gap-4">
                    <h1 className='font-bold text-purple-900 text-2xl mt-6 self-start font-semibold row-start-2 row-span-1 lg:row-start-1 lg:row-spand-1 lg:col-start-2 lg:col-span-full'>
                        About Me
                    </h1>
                    <div className='relative px-4 shadow-md m-auto rounded-xl bg-purple-200 lg:col-start-1 lg:col-span-1 lg:row-start-1 lg:row-span-2 '>
                        <img 
                            className='w-60 h-auto lg:w-60 lg:h-auto'
                            src={require('../assets/about2-img.png').default}
                            // src="https://i.pinimg.com/originals/77/fc/a9/77fca9754a11fbd15aee50d44bd317e9.jpg" 
                            alt="Best Realtor profile image." 
                        />
                    </div>
                    <p className='text-md lg:text-lg lg:col-start-2 lg:row-start-2'>
                        I bring over 40 years of sales experience to real estate. I served as the President and the CEO of major companies during most of my career.  Whether buying or selling a home, you need my experience by your side. I am a hard working individual with great listening skills. I provide quality service to build strong relationships with clients. And more importantly maintain those relationships by communicating efficiently. My ease of communication and market knowledge, coupled with proven negotiation skills allow me to consistently have the winning offer at the best price in multiple offer situations.
                    </p>
                </div>

                <div>
                    <h2 className='font-semibold  text-purple-900 text-2xl mt-6 self-start font-bold'>What can you expect</h2>
                    <div className="grid grid-cols-1 gap-4 justify-center items-center mt-8 lg:grid-cols-3">
                        <Card 
                            imgUrl='https://caspian-wp-content.s3.eu-west-1.amazonaws.com/uploads/2016/09/26132532/Business-handshake-contract.jpg'
                            title='You are my priority'
                            text={`I'll put your best interests first and guide you through the process, from start to finish.`}
                        />
                        <Card 
                            imgUrl='https://live.staticflickr.com/5615/15029208603_d4767295f5_c.jpg'
                            title='Fast, reliable and flexible.'
                            text='I will take care of everything for you, so that you can enjoy your new home.'
                        />
                        <Card 
                            imgUrl='https://a.cdn-hotels.com/gdcs/production37/d1139/a8a147d0-c31d-11e8-825c-0242ac110006.jpg'
                            title='I am a house expert.'
                            text={`I am the best agent to help you buy or sell your home. I'll help with any issues that may arise.`}
                        />
                    </div>
                </div>

            </Container>
            
            <div className="py-4 bg-purple-600 w-full px-8 mt-8 lg:py-12">
                <p className='text-gray-50 lg:text-2xl text-center'>
                    “A house is made of bricks and beams. A home is made of hopes and dreams. I'll help you find your home.”
                </p>
            </div>
        </section>
    )
}

export default About
