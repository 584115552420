import React, {useEffect} from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Icon = (props) => (
    <a href={props.href}>
        <motion.img className='w-8 h-8 shadow-lg cursor-pointer' {...props}
            whileHover={{ scale: 1.2 }}
        />
    </a>

)

const Home = ({homeRef}) => {
    return (
        <AnimatePresence exitBeforeEnter>
            <section className='w-screen h-screen flex' id='home' ref={homeRef}>
                <div className='w-screen lg:w-full h-full bg-gray-300 bg-opacity-90'>
                    <motion.div className="w-full h-full flex flex-col text-white justify-center items-center"
                        initial={{ x: '-100%'}}  
                        animate={{ x: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className='bg-home bg-cover w-32 h-32 lg:w-40 p-4 lg:h-40 rounded-full  border-4 border-solid border-gray-50 z-50'>
                        </div>
                        
                        <div>
                            <p className='text-2xl lg:text-4xl font-anton text-gray-700'><span className='text-purple-700'>H</span>akan</p>
                            <p className='text-4xl lg:text-6xl font-anton text-gray-700'><span className='text-purple-700'>A</span>ltunbas <span className='uppercase text-2xl lg-text-4xl'><span className='text-purple-900'>Realtor</span></span></p>
                            <p className='text-sm lg:text-lg text-gray-700'>Helping you find the property of your dreams.</p>
                        </div>
                        <motion.div className='flex gap-8 w-full justify-center items-center self-end pt-24'
                            initial={{ x: '-100%'}}  
                            animate={{ x: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Icon src={require('../assets/instagram.png').default} alt='Visit my Instagram page' href='https://instagram.com/vegasbestrealtor'/>
                            <Icon src={require('../assets/linked-in.png').default} alt='Visit my Linked In profile' href='https://www.linkedin.com/in/hakan-altunbas-b794094/'/>
                        </motion.div>
                    </motion.div>
                </div>
                {/* <div className='hidden lg:block lg:w-1/2 h-full'>
                    <motion.img className='h-full w-full' src={require('../assets/home.jpg').default} alt="home"
                        // initial={{ opacity: 0}}  
                        // animate={{ opacity: 1}}
                        // transition={{ duration: 0.3, delay: 0.1 }}
                    />
                </div> */}
            </section>
        </AnimatePresence>
    )
}

export default Home
